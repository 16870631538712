<template> 
        <CForm ref="form" class="needs-validation">
            <CRow>
                <CCol tag="label" sm="3" class="col-form-label">
                    Language
                </CCol>
                <CCol>
                    <CSelect
                        placeholder="Language"
                        :options="langs"
                        :value.sync="data.templateLang"
                        required
                        @change="changeLang(data.templateLang)"
                    />
                </CCol>
            </CRow>

            <draggable v-model="data.photos" :move="onMoveCallback" @start="drag=true" @end="drag=false" handle=".handle">

                <CRow v-for="(p,i) in data.photos" :key="i" >
                    <CCol col=".5" class="d-flex align-items-center ml-2 mr-2 handle">
                        <span  > <CIcon name="cilMove" size="sm" /></span>
                    </CCol>
                    <CCol col="1.5" tag="label" class="col-form-label d-flex align-items-center">
                        {{ i+1 }}. Resim
                    </CCol>
                    <CCol col="4" class="d-flex align-items-center">
                        <input 
                            type="file"  
                            @change="handleFileUpload($event, i)"
                            accept="image/jpeg, image/gif"
                            :ref="'file'+i"
                            :required="!(typeof data.photos[i] == 'string')"
                        />
                    </CCol>
                    <CCol col="4">
                        <CInput 
                            type="text"  
                            v-model="data.links[i]"
                            placeholder="Resim için Link"
                            required
                            :ref="'link'+i"
                        />
                    </CCol>
                    <CCol col="2">
                        <CButton color="danger" size="sm" @click="removePhoto(i)" class="mr-1"> <CIcon name="cilX" size="sm" /></CButton>
                        <img
                             v-if="data.photos[i].size > 0 || (typeof data.photos[i] == 'string' && data.photos[i] !== '') "
                             :src="data.photos[i].size > 0 ? createObjectURL(data.photos[i]) : data.photos[i] "
                             @click="openPreview(i)" width="48" height="48" class="mr-1"
                        />  
                    </CCol>
                </CRow>
            </draggable>
            
            
            <CButton color="success" size="sm" @click="addPhoto"> Yeni Resim Ekle </CButton>
        
            <ConfirmModal
                :show="openModal" 
                :title="modalTitle" 
                :openModalEvent="openModalEvent"
                :confirm="()=>{}"
                :desc="modalDesc"
                :noFooter="true"
                size="lg"
            />
        </CForm>
               
                
           
</template>

<script>
    import ConfirmModal from '../../components/confirmModal.vue'
    import draggable from 'vuedraggable'

    export default{
        name: "NewsletterForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            lang: String,
        },
        components:{ConfirmModal, draggable},
        computed:{
            langs: function() {
                let data = []
                this.$store.getters.langs.map(r => data.push({id: r.id, value:r.localeCode, label: r.name}))
                return data
            },
            newsletterPhotos: function() {
                return this.$store.getters.newsletterPhotos
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};

            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params}
                }
            },
            lang: function(val){
                this.data.templateLang = val
            },
            validated:  function(){
                this.validity()
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                modalDesc: '',
                modalTitle: '',
                openModal: false,
                webDomain: process.env.VUE_APP_SERVERINFO_WEBDOMAIN,
            }
        },
        mounted: function(){
            if(this.data.photos.length == 0 && this.data.templateLang){
                this.changeLang(this.data.templateLang)
            } 
        },
        methods:{

            onMoveCallback: function(evt, originalEvent){
                this.data.ids.splice(evt.draggedContext.index, 0, this.data.ids.splice(evt.draggedContext.futureIndex, 1)[0]);
                this.data.links.splice(evt.draggedContext.index, 0, this.data.links.splice(evt.draggedContext.futureIndex, 1)[0]);
            },

            createObjectURL(data){
                return URL.createObjectURL(data)
            },

            changeLang: async function(lang){
                let id = this.langs.find( l => l.value == lang).id
                let formData = new FormData;
                formData.append('newsLetterId', this.data.id)
                formData.append('langId', id)
                await this.$store.dispatch('newsletterTemplate_getPhoto', formData)
                this.data.photos = []
                this.data.links = []
                this.data.ids = []
                this.$store.getters.newsletterPhotos.map( (photo) => {
                    this.data.ids.push(photo.id)
                    this.data.links.push(photo.imageLink)
                    this.data.photos.push(this.webDomain + '/email/images/'+lang+'/newsletter/'+ this.data.id +'/'+ photo.imageUrl)
                })

            },

            validity(){

                let emptyInputs = []
                this.data.photos.map((p, i) => {
                    if(typeof p == "string" && p == "")
                        emptyInputs.push(i + 1)
                    else if(typeof p !== "string" && !p.size )
                        emptyInputs.push(i + 1)

                })

                if(emptyInputs.length > 0){
                    const toast = {
                        msg: 'Eksik resim dosyası '+emptyInputs.join(', ') + ' sıradaki dosyaları da doldurun',
                        header: 'Hata',
                        color: 'danger'
                    }
                    this.$store.commit('showToast', toast)
                }
                
            },

            openModalEvent(title, desc){
                this.openModal = !this.openModal
                this.modalTitle = title
                this.modalDesc = desc
            },

            openPreview: function(i){
                let photo = null
                if(this.data.photos[i].size){
                    photo = URL.createObjectURL(this.data.photos[i])
                } else if(this.data.photos[i] !== ""){
                    photo = this.data.photos[i]
                }else{
                    let msg = {
                        msg: 'Önizlenecek görsel bulunamadı.',
                        header: 'Hata',
                        color: 'danger'
                    }
                    this.$store.commit('showToast', msg)
                    return
                }
                this.openModalEvent('Önizleme', '<img src="'+photo+'" style="width:100%"/>')
            },

            addPhoto: function(){
                this.data.photos.push({})
                this.data.links.push(null)
                this.data.ids.push(0)
            },

            removePhoto: function(index){
                this.$refs['file'+index][0].value = ''
                this.data.photos.splice(index, 1);
                this.data.links.splice(index, 1);
                this.data.ids.splice(index, 1);
            },

            handleFileUpload: function(event, index){
                let file = event.target.files[0]
                let status = true
                let msg = {}
                if(file.size > 1500000){
                    status = false
                    msg = {
                        msg: 'Görsel boyutu en fazla 1.5 mb olabilir',
                        header: 'Hata',
                        color: 'danger'
                    }
                }
               
                if(status){
                    this.$set(this.data.photos,index, file);
                }else{
                    event.target.value = ''
                    this.$store.commit('showToast', msg)
                }
            }
        }
    }
</script>